import React, {Component} from "react"
import Layout from "../components/layout"
import PopupCTAScreen from "./popup-cta"
import Button from '@material-ui/core/Button'
import SEO from "../components/seo";
import Modal from '@material-ui/core/Modal';
import BreadCrumbs from "../components/bread-crumbs";
import "./Inner-cta.scss";


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class MainCTAScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        }
    }

    handleOpen = () => {
        this.setState({openModal: true});
    };

    handleClose = () => {
        this.setState({openModal: false});
    };

    render() {

        return (
            <Layout>
                <SEO title="CTA"/>
                <BreadCrumbs currentPage={"Main CTA"} breadCrumbsList={breadCrumbsList}/>
                <div className="CTA-box">
                    <Button href="/inner-CTA/" className="blue-simple-btn"
                            color="primary">
                        Inner CTA's
                    </Button>

                    <Button className="blue-simple-btn" color="primary"
                            onClick={this.handleOpen}>
                        Popup CTA's
                    </Button>

                    <Button href="/separate-CTA/" className="blue-simple-btn"
                            color="primary">
                        Separate CTA's
                    </Button>
                </div>

                <Modal
                    className="modalStyle1"
                    open={this.state.openModal}
                    onClose={this.handleClose}
                    disableScrollLock={true}
                >
                    <PopupCTAScreen/>
                </Modal>

            </Layout>
        )
    }
}

export default MainCTAScreen
